import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  waitingRoomContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      height: "100%",
    },
  },

  buttonStart : {
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      bottom: "0",
      left: "0",
      width: "100%",
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#e7ecf5",
      color: theme.palette.primary.contrastText,
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  leftSide: {
    maxWidth: "560px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0 50px",
  },
  logo: {
    margin: "0 0 80px",
  },
  boldTitle: {
    fontSize: "40px",
    fontWeight: 700,
    color: theme.palette.black.main,
    margin: "0",
  },
  subText: {
    fontSize: "18px",
    fontWeight: 300,
    color: theme.palette.black.main,
    margin: "20px 0 30px",
  },
  text: {
    fontSize: "22px",
    fontWeight: 300,
    color: theme.palette.black.main,
    margin: "10px 0",
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    maxWidth: "420px",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      alignItems: "center",
    },
  },
  formControl: {
    width: "100%",
  },
  form: {
    width: "100%",
  },
  mediaSources: {
    margin: "10px 0 20px",
    display: "flex",
    flexDirection: "column",
  },
  waitingRoomVideoPreview: {
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      padding: "20px",
    },
  },
  deviceContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  deviceSettings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
    [theme.breakpoints.down('sm')]: {
      alignItems: "center",
    },
  },
  networkTestContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 5px",
  },
  flex: {
    display: "flex",
  },
  root: {
    width: "20%",
  },
}));
