import { useContext, useEffect, useRef, useState } from "react";
import useStyles from "./styles.js";
import useRoom from "../../hooks/useRoom";
import useScreenSharing from "../../hooks/useScreenSharing";
import { UserContext } from "../../context/UserContext";

import ToolBar from "../ToolBar";
import NetworkToast from "../NetworkToast";
import { retrieveToken } from "../../api/fetchCreds.js";

export default function VideoRoom() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const {
    createCall,
    room,
    participants,
    connected,
    networkStatus,
    cameraPublishing,
    localParticipant,
    togglePIP
  } = useRoom();

  const [credentials, setCredentials] = useState({});

  useEffect(() => {
    const init = async () => {
      const dataToLoad = await retrieveToken();
      setCredentials(dataToLoad);
    };
    init();
  }, []);

  const { isScreenSharing, startScreenSharing, stopScreenSharing } =
    useScreenSharing({ room });
  const roomContainer = useRef();

  useEffect(() => {
    if (credentials?.sessionId) {
      createCall(
        credentials,
        roomContainer.current,
        credentials.username,
        user.videoEffects,
        {
          ...user.defaultSettings,
        }
      );
    }
  }, [createCall, credentials, user]);

  return (
      <div id="callContainer" className={classes.callContainer}>
        <div
            id="roomContainer"
            className={classes.roomContainer}
            ref={roomContainer}
        >
          <NetworkToast networkStatus={networkStatus}/>
          <div
              id="screenSharingContainer"
              className={classes.screenSharingContainer}
          >
            {isScreenSharing && (
                <div className={classes.screenSharingOverlay}>ecran partagé</div>
            )}
          </div>
        </div>
        <ToolBar
            room={room}
            participants={participants}
            localParticipant={localParticipant}
            connected={connected}
            cameraPublishing={cameraPublishing}
            isScreenSharing={isScreenSharing}
            startScreenSharing={startScreenSharing}
            stopScreenSharing={stopScreenSharing}
            togglePIP={togglePIP}
        ></ToolBar>
      </div>
  );
}
